<template>
  <div class="table-manager__wrap">
    <el-table
      ref="tableControl"
      :key="updateTable"
      v-loading="isLoading"
      class="table-manager"
      :data="tableData"
      :row-class-name="tableRowClassName"
      stripe
      @selection-change="handleSelectionChange"
      @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave"
    >
      <!-- чекбоксы мультивыбора отображаем только руту и админу -->
      <el-table-column
        v-if="(isSuperAdmin || isAdmin) && tableData.length"
        type="selection"
        width="55"
      />

      <el-table-column
        v-if="!isViewer"
        align="left"
        :width="getWidthIconColumn"
        class-name="table-control__icon"
      >
        <template slot="header">
          <el-dropdown
            trigger="click"
            class="table-control__header-dropdown"
            @command="handleTableMenu"
          >
            <span class="el-dropdown-link">
              <Icon
                name="IconTableMenu"
                width="37px"
                height="37px"
                class="table-control__menu"
              />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in tableDropdownMenuList"
                :key="item.id"
                :class="item.class"
                :divided="item.isDivided"
                :command="item.value"
              >
                {{ item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>

        <template slot-scope="scope">
          <el-tooltip
            v-if="hasArrivalConfirmButton"
            class="item"
            effect="dark"
            content="Подтвердить приезд"
            placement="top"
          >
            <Icon
              width="20px"
              height="20px"
              :disabled="isDisabledArrivalBtn(scope)"
              class="table-control__menu btn-icon-svg"
              name="IconAccept"
              @click.native="handleForceArrival(scope)"
            />
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="редактировать"
            placement="top"
          >
            <Icon
              v-if="hasIconEdit"
              name="IconTableEdit"
              width="20px"
              height="20px"
              :disabled="isDisabledEditBtn(scope.row)"
              class="table-control__menu btn-icon-svg"
              @click.native="handleEdit(scope.row)"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="удалить"
            placement="top"
          >
            <Icon
              name="IconTableDelete"
              width="20px"
              height="20px"
              :disabled="isDisabledDeleteBtn(scope.row)"
              class="table-control__menu btn-icon-svg"
              @click.native="handleDelete(scope.row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
        align="left"
        width="70px"
        class-name="table-control__icon-log-wrap"
      >
        <template slot="header"> Инфо </template>

        <template slot-scope="scope">
          <Icon
            name="IconInfo"
            width="20px"
            height="20px"
            class="table-control__menu btn-icon-svg"
            @click.native="handleGoToLogs(scope.row)"
          />
        </template>
      </el-table-column>

      <template v-for="item in controlTableFields">
        <el-table-column
          :key="item.id"
          align="left"
          :width="item.width ? item.width : ''"
          :class-name="`table-manager__${item.prop}`"
          :sortable="item.sortKey"
        >
          <template slot="header">
            <TableLabel
              :data="item"
              :sortable="item.sortKey"
              @sortBy="handleSort"
            />
          </template>

          <template slot-scope="scope">
            <slot :data="{ row: scope.row, prop: item.prop }" />
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import Icon from '@/UI/icon/Icon'
import TableLabel from '@/UI/table/table-body/components/TableLabel'
export default {
  name: 'ManagerTableBody',
  components: { TableLabel, Icon },
  props: {
    tableData: { type: Array, required: true },
    tableFieldsDesktop: { type: Array, required: true },
    tableFieldsTablet: { type: Array, required: true },
    tableFieldsMobile: { type: Array, required: true },
    tableFieldsSmallMobile: { type: Array, required: true },
    tableDropdownMenuList: { type: Array, required: true },
    hasIconEdit: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      multipleSelectionIds: [],
      hoverRowIds: [],
    }
  },
  computed: {
    controlTableFields() {
      if (this.isSmallMobile) {
        return this.tableFieldsSmallMobile
      }
      if (this.isMobile) {
        return this.tableFieldsMobile
      }
      if (this.isTablet) {
        return this.tableFieldsTablet
      }

      return this.tableFieldsDesktop
    },
    updateTable() {
      if (this.isSmallMobile) {
        return 'smallMobile' + this.tableFieldsDesktop.length
      }
      if (this.isMobile) {
        return 'mobile' + this.tableFieldsDesktop.length
      }
      if (this.isTablet) {
        return 'tablet' + this.tableFieldsDesktop.length
      }

      return 'desktop' + this.tableFieldsDesktop.length
    },
    getWidthIconColumn() {
      if (this.isMobile) {
        return '42'
      }

      return this.isSuperAdmin ? '140' : '130'
    },
    hasArrivalConfirmButton() {
      return (
        this.isSuperAdmin || this.isAdmin || this.isSupport || this.isManager
      )
    },
  },
  methods: {
    tableRowClassName({ row }) {
      if (this.multipleSelectionIds.includes(row.id)) {
        return 'active-row'
      }
      if (this.hoverRowIds.includes(row.id)) {
        return 'hover-row'
      }

      return ''
    },
    handleSelectionChange(val) {
      const selectedList = val.map(item => ({
        id: item.id,
        truck_status: item.truck_status,
      }))

      this.multipleSelectionIds = val.map(item => item.id)
      this.$emit('selectionChange', selectedList)
    },
    handleTableMenu(command) {
      this.$emit('selectDropdownMenu', command)
    },
    handleEdit(row) {
      if (!this.isDisabledEditBtn(row)) {
        this.$emit('editRow', row)
      }
    },
    handleDelete(row) {
      if (!this.isDisabledDeleteBtn(row)) {
        this.$emit('deleteRow', row)
      }
    },
    handleSort(sortKey) {
      this.$emit('tableSortByKey', sortKey)
    },
    handleCellMouseEnter(row) {
      this.hoverRowIds = []
      this.hoverRowIds.push(row.id)
    },
    handleCellMouseLeave() {
      this.hoverRowIds = []
    },
    isDisabledArrivalBtn({ row }) {
      return !(row.truck_status === 'not_stated' || row.truck_status === 'late')
    },
    handleForceArrival({ row }) {
      // обрабатываем  нажатия только по активным: в пути и опоздавшим
      if (row.truck_status === 'not_stated' || row.truck_status === 'late') {
        this.$emit('forceArrival', row)
      }
    },
    // сейчас используется для двух кнопок - и редактирования, и удаления. Если логика станет отличаться,
    // тогда сделать для кнопки удаления отдельную.
    isDisabledEditBtn(row) {
      return row.truck_status === 'arrived'
    },
    isDisabledDeleteBtn(row) {
      return (
        ['arrived', 'late', 'detached'].includes(row.truck_status) ||
        Boolean(this.multipleSelectionIds.length)
      )
    },
    handleGoToLogs(data) {
      this.$emit('goToLogs', data)
    },
  },
}
</script>

<style lang="sass">
.table-manager
  border: 1px solid #E4E7ED
  border-radius: 4px
  overflow: hidden
  &__wrap
    padding: 0 $space-16
    .el-table .el-table__cell
      padding: 5px 0
.btn-icon-svg
  margin: 0 6px
</style>
